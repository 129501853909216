import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SteamerBackground from "./steamer-bg.jpg";
import "./SignUpDialog.css";

const SignUpDialog = () => {
  const [cookies, setCookie] = useCookies(["visited"]);
  const handleClose = () => {
    setCookie("visited", true, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
  };
  const handleSubmit = () => {
    setCookie("visited", true, {
      path: "/",
      expires: new Date(new Date().setMonth(new Date().getMonth() + 36)),
    });
  };
  return (
    <Dialog open={cookies.visited == null} onClose={handleClose}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), url(${SteamerBackground})`,
          backgroundPosition: "center top",
          backgroundSize: "100% auto",
        }}
        className="signUpDialogStyle"
      >
        <DialogTitle>BE THE FIRST TO KNOW 🚀</DialogTitle>
        <form name="sign-up" data-netlify="true">
          <DialogContent>
            <DialogContentText>
              To subscribe to Serious Dumpings 🥟, please enter your email
              address here. We will send updates occasionally. 😀
            </DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              name="email"
              fullWidth
              variant="standard"
              sx={{ color: "white" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} type="submit">
              Subscribe
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default SignUpDialog;
