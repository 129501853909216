import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "./index.css";
import App from "./Pages/App/App";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./scrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <CookiesProvider>
      <ScrollToTop />
      <App />
    </CookiesProvider>
  </HashRouter>
);
