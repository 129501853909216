import { Box, Grid, Button } from "@mui/material";
import logo from "./logo-red.png";
import "./Footer.css";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box className="footer-box">
      <Box>
        <Grid
          container
          spacing={3}
          columns={{ xs: 12, sm: 12, md: 12 }}
          sx={{
            backgroundColor: "#c30e23",
            px: "20px",
            pt: "20px",
            pb: "30px",
            boxShadow: "0 -5px 20px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} sm={12} md={3} container direction="row" justifyContent="center" alignItems="center">
            <img src={logo} alt="Serious Dumplings Logo White" style={{ maxWidth: "150px", paddingTop: "10px" }} />
            <h2
              style={{
                color: "#860211",
                marginTop: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              Serious Sanji
            </h2>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="footer-grid">
            <h2>Locations</h2>
            <a href="/#/locations">
              <Button size="small" className="locationCardButton" variant="outlined">
                View All Locations
              </Button>
            </a>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="footer-grid">
            <h2>Hours</h2>
            Monday - Thursday & Sunday
            <br />
            11:30 AM - 9:30 pm
            <br />
            Friday - Saturday:
            <br />
            11:30 AM - 10:00 pm
          </Grid>
          <Grid item xs={12} sm={12} md={3} className="footer-grid">
            <h2>Follow Us</h2>
            <Stack direction="row" spacing={1}>
              <a href="#/">
                <Avatar sx={{ bgcolor: "#a10a1b" }}>
                  <TwitterIcon />
                </Avatar>
              </a>
              <a href="#/">
                <Avatar sx={{ bgcolor: "#a10a1b" }}>
                  <InstagramIcon />
                </Avatar>
              </a>
              <a href="#/">
                <Avatar sx={{ bgcolor: "#a10a1b" }}>
                  <FacebookIcon />
                </Avatar>
              </a>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "#a10a1b",
          color: "#dedede",
          textAlign: "center",
          padding: ".8rem",
        }}
      >
        © {new Date().getFullYear()} SeriousDumplingsUSA.COM. All Rights Reserved. | Created with ❤ by&nbsp;
        <a href="https://www.sparkdesigner.com" target="_blank" alt="SparkDesigner Web Design" rel="noreferrer" style={{ color: "#dedede" }}>
          SparkDesigner
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
