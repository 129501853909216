import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Home from "../Home/Home";
import Menu from "../Menu/Menu";
import Locations from "../Locations/Locations";
import Franchise from "../Franchise/Franchise";
import Contact from "../Contact/Contact";
import SignUpDialog from "../../Components/SignUpDialog/SignUpDialog";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c30e23",
    },
    secondary: {
      main: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <SignUpDialog />
      <div style={{ minHeight: "50vh" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="menu" element={<Menu />} />
          <Route path="locations" element={<Locations />} />
          <Route path="franchise" element={<Franchise />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
