import { Box, TextField, Button } from "@mui/material";
import "./ContactForm.css";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mgebblgb");
  return (
    <>
      <form className="formContainer" name="contact" onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }} className="formInput">
          <Box sx={{ width: "50%", pr: "5px" }}>
            <TextField fullWidth required id="first-name" label="First Name" type="text" name="first-name" variant="outlined" />
            <ValidationError prefix="First Name" field="first-name" errors={state.errors} />
          </Box>
          <Box sx={{ width: "50%", pl: "5px" }}>
            <TextField fullWidth id="last-name" label="Last Name" type="text" name="last-name" variant="outlined" />
            <ValidationError prefix="Last Name" field="last-name" errors={state.errors} />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} className="formInput">
          <TextField fullWidth required id="e-mail" label="Email" type="email" name="email" variant="outlined" />
          <ValidationError prefix="First Name" field="first-name" errors={state.errors} />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} className="formInput">
          <TextField id="outlined-multiline-static" label="Message" required multiline rows={6} type="text" name="message" variant="outlined" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Button variant="outlined" size="large" type="submit" disabled={state.submitting}>
            Submit
          </Button>
          <p>{state.succeeded ? "Submission success!" : ""}</p>
        </Box>
      </form>
    </>
  );
};

export default ContactForm;
