import { Box } from "@mui/material";

const PageTitleContainer = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: { xs: "200px", md: "280px" },
        textAlight: "center !important",
        background: `linear-gradient(0deg, rgba(51, 51, 51, 0.20), rgba(51, 51, 51, 0.20)), url(${props.backgroundImage})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        color: "white",
        backgroundAttachment: "fixed",
        textTransform: "uppercase",
        textShadow: "0 2px 0 rgba(0, 0, 0, 0.5)",
        fontSize: "1.500em",
        paddingBottom: "20px",
      }}
    >
      <h1>{props.title}</h1>
    </Box>
  );
};

export default PageTitleContainer;
